import { load } from 'js-yaml';
import pako from 'pako';

export class AjaxRequest {
    public static async getPlainText(url: string, data: Object = {}) {
        try {
            const response = await fetch(url);
            if ( ! response.ok ) { console.log('Error while retrieving plainText'); return null; }
            const responseText = await response.json();
            return responseText;
        }
        catch( err ) { console.log( err ); return null; }
    }

    // or as chain: awaitch fetch(url).then(response => response.text().then( async (responseContent) => {data = await load(fileContent)}))
    public static async getYaml(url:string, data = {} ):Promise<any> {
        try {
            let res:Response| string;
            // Normal way
            if ( ! url.includes('.gz') ) {
                const response = await fetch(url);
                res = await response.text();
            }
            else {
                const response = await fetch(url, { headers: { 'Accept-Encoding': 'gzip' } });
                res = await this.decompressResponse(response);
            }
            const yamlData = load(res);
            return yamlData;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }

    private static async decompressResponse(response: Response): Promise<string> {
        const reader = response.body?.getReader();
        if (!reader) throw new Error('Response body is empty or not readable.');

        const chunks: Uint8Array[] = [];
        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            chunks.push(value);
        }
        const buffer = new Uint8Array(chunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []));
        return new Promise<string>((resolve, reject) => {
            const inflate:any = new pako.Inflate();
            inflate.push(buffer, true);
            if (inflate.err) {
                reject(new Error('Error decompressing response'));
            } else {
                resolve(new TextDecoder().decode(inflate.result));
            }
        });
    }

    public static async getXML(url: string, data: Record<string, any> = {}): Promise<Document | null> {
        try {
            const response = await fetch(`${url}?${new URLSearchParams(data)}`);
            if ( !response.ok ) throw new Error(`Failed to fetch XML. Status: ${response.status}`);

            const text = await response.text();
            return new DOMParser().parseFromString(text, 'text/xml');
        } catch (error) {
            console.error(`Error fetching or parsing XML: ${error.message}`);
            return null;
        }
    }
    public static async getJsObject ( url:string, data:Object = { } ) {
        let res: Object = null;
        if ( url.endsWith( '.json' ) ) {
            res = await this.getJson( url, data);
        }
        else if( url.endsWith( '.yaml' ) ) {
            res = await this.getYaml(url, data);
        }
        return res;
    }

    public static async getJson( url:string, data = {} ) {
        try {
            const response = await fetch(`${url}?${new URLSearchParams(data)}`);
            if (!response.ok) throw new Error(`Failed to fetch YAML. Status: ${response.status}`);
            return await response.json();
        } catch (error) {
            console.error(`Error fetching or parsing YAML: ${error.message}`);
            return null;
        }
    }
}