import { Modules } from "./Modules";

export var modules: Modules = new Modules();
var timer = performance.now();
var boolo = false;
declare var window: any;
modules.preInit();
document.addEventListener("DOMContentLoaded", async function() {
    try {
        await boolVal();
        console.log( performance.now());
    }
    catch(err) { console.log(err); }
});

const boolVal = async ()=> {
    boolo = await modules.checkRequirements();
    if ( boolo ) await modules.init();
}

/** NEW */
// window.ezmodules = modules;
// console.log('%c Script started: ' +performance.now(), 'background: #000; color: red');
// import { Modules } from "./Modules";
// export var modules: Modules = new Modules();
// var boolo = false;
// declare var window: any;
// window.ezmodules = modules;
// console.log('%c Script finished: ' +performance.now(), 'background: #000; color: red');