import { LogEntry } from "./LogEntry";
// import { ModuleExecutionStatus } from "../Globals";

export class Logging {

    public static ERROR_LOG:Logging = new Logging( "Error Log", "red" );
    public static MODULES:Logging = new Logging( "Modul-Info", "green" );
    public static GENERAL_LOG:Logging = new Logging( "Allgemeiner Log", "blue" );

    private name:string;
    private color:string;

    private log:Array<LogEntry>;
    static Modules: any;
    static Times: any;
    static endTime: any;
    static startTime: any;
    static modules: any;
    static flagAllowCookies: any;
    static modulesConfiguration: any;
    static v2Modals: any;
    static ModuleExecutionStatus: any;

    private constructor ( name:string, color:string ){
        this.name = name;
        this.color = color;
        this.log = new Array();
    }

    public getName ():string{
        return this.name;
    }

    public geColor ():string{
        return this.color;
    }

    public print ():void{
        console.log( "" );
        console.log( "%c********************" + "*".repeat( this.name.length ), "color: " + this.color + ";" );
        console.log( "%c*******   "+ this.name +"   *******", "color: " + this.color + ";" );
        console.log( "%c********************" + "*".repeat( this.name.length ), "color: " + this.color + ";" );
        for (let i = 0; i < this.log.length; i++) {
            if ( this.log[i].getOutputStyle() == "" ){
                console.log( this.log[i].getMessage() );
            } else {
                if ( this.log[i].getOutputStyle() instanceof Array ){
                    var args = new Array( this.log[i].getMessage() ).concat( this.log[i].getOutputStyle() );

                    console.log.apply( console, args );
                } else {
                    console.log( "%c" + this.log[i].getMessage(), this.log[i].getOutputStyle() );
                }
            }
        }
    }

    /** @Chris
     * @description print() method but for html
     * @param contentEle = contentHTMLElement
     */
    public debugPrint (contentEle:HTMLDivElement):void {
        const divEle:HTMLDivElement = document.createElement('div');
        divEle.style.color = this.color;
        divEle.innerHTML =`********************${"*".repeat( this.name.length )}`;
        contentEle.append(divEle);

        const divEle2:HTMLDivElement = document.createElement('div');
        divEle2.style.color = this.color;
        divEle2.innerHTML =`*******     ${this.name}     *******`;
        contentEle.append(divEle2);

        const divEle3:HTMLDivElement = document.createElement('div');
        divEle3.style.color = this.color;
        divEle3.innerHTML =`********************${"*".repeat( this.name.length )}`;
        contentEle.append(divEle3);

        for (let i = 0; i < this.log.length; i++) {
            if (this.log[i].getMessage() === '' && this.log[i].getOutputStyle() === '') { continue; }
            if ( this.log[i].getOutputStyle() == "" ) {
                let divEle1 = document.createElement('div');
                divEle1.innerHTML = this.log[i].getMessage();
                contentEle.append(divEle1);
            } else {
                // if multiple colors
                if ( this.log[i].getOutputStyle() instanceof Array ) {
                    var args = new Array( this.log[i].getMessage() ).concat( this.log[i].getOutputStyle() );
                    const divEle2 = document.createElement('div');

                    const spanEle = document.createElement('span');
                    spanEle.style.color = args[1].replace('color:', '').replace(';', '');
                    spanEle.innerHTML = args[0].split('%c')[1];

                    divEle2.append(spanEle);
                    const spanEle2 = document.createElement('span');
                    spanEle2.style.color = args[2].replace('color:', '').replace(';', '');
                    spanEle2.innerHTML = args[0].split('%c')[2];
                    divEle2.append(spanEle2);
                    contentEle.append(divEle2);
                }
                else {
                    let divEle3 = document.createElement('div');
                    divEle3.style.color = this.log[i].getOutputStyle();
                    divEle3.innerHTML =this.log[i].getMessage();
                    contentEle.append(divEle3);
                }
            }
        }
    }

    public add( message:string, outputStyle:string = "", useStrackTrace:boolean = true ):void{
        if ( useStrackTrace ){
            var error:Error = new Error( message );

            if ( typeof Error.captureStackTrace === "function"){
                Error.captureStackTrace( error, this.add );
                this.log.push( new LogEntry( error, outputStyle ) );
            } else {
                this.log.push( new LogEntry( error.stack, outputStyle ) );
            }
        } else {
            this.log.push( new LogEntry( message, outputStyle ) );
        }
    }

    public addWithMultipleStyles ( message:string, styles:Array<string> ):void {
        this.log.push( new LogEntry( message, styles ) );
    }

    public clear (){
        this.log = new Array();
    }


    public static logginStart(
        Modules:any,
        Times:any ,
        endTime:any ,
        startTime:any ,
        modules:any ,
        flagAllowCookies:any ,
        modulesConfiguration:any,
        v2Modals:any,
        ModuleExecutionStatus:any

    ) {
        Logging.Modules =                   Modules;
        Logging.Times =                     Times;
        Logging.endTime =                   endTime;
        Logging.startTime =                 startTime;
        Logging.modules =                   modules;
        Logging.flagAllowCookies =          flagAllowCookies;
        Logging.modulesConfiguration =      modulesConfiguration;
        Logging.v2Modals =                  v2Modals;
        Logging.ModuleExecutionStatus =     ModuleExecutionStatus;

        Logging.additionalLogVariant();
    }

    /** Show the logging by pressing the following keys: Ctrl + Cmd + L */
    public static printLogEntrys(): void {
        Logging.buildModulesLog();
        console.clear();
        console.log("%c*************************************", "color: gray;");
        console.log("%c*******   eZentrum Modules   ********", "color: gray;");
        console.log("%c*******   Version: " + Logging.Modules.VERSION + "    ********", "color: gray;");
        console.log("%c*************************************", "color: gray;");
        Logging.MODULES.print();
        Logging.logMes("Alle Module wurden in " + Logging.Times.getTimeString(Logging.endTime - Logging.startTime, "s") + " ausgeführt");
        Logging.GENERAL_LOG.print();
        Logging.ERROR_LOG.print();
    }

    public static logMes(message: string, outputStyle: string = ""): void {
        Logging.GENERAL_LOG.add(message, outputStyle, false);
    }

    public static errorMes( message: string, useStrackTrace: boolean = true, outputStyle: string = "" ): void {
        Logging.ERROR_LOG.add( message, outputStyle, useStrackTrace );
    }

    private static buildModulesLog() {
        Logging.MODULES.clear();
        for ( let i = 0; i < Logging.modules.length; i++ ) {
            let text: string = "%c" + Logging.modules[i].getName() + ": %c" + ( Logging.modules[i].isActive() ? "" : "in" ) + "aktiv";
            let style: Array<string> = new Array(
                "color: grey;",
                "color:" + ( Logging.modules[i].isActive() ? "green" : "red" ) + ";"
            );
            Logging.MODULES.addWithMultipleStyles( text, style );
        }
        Logging.MODULES.add("", "", false);
        for ( let i = 0; i < Logging.modules.length; i++ ) {
            let module = Logging.modules[i];
            if ( module.isInitialized() && module.isActive() && ! ( ! Logging.flagAllowCookies && module.useCookies()) ) {
                let moduleExecutionStatus: any = module.getExecutionStatus();
                if ( moduleExecutionStatus != null ) {
                    let color = new Array(
                        "color: grey;"
                    );
                    switch( moduleExecutionStatus ) {
                        case Logging.ModuleExecutionStatus.SUCCESSFULL:
                            color.push("color: green;");
                            break;
                        case Logging.ModuleExecutionStatus.NOT_CATCHED_ERROR:
                            color.push("color: red;");
                            break;
                        default:
                            color.push("color:orange;");
                    }
                    moduleExecutionStatus = moduleExecutionStatus.replace("[module_name]", module.getName());
                    Logging.MODULES.addWithMultipleStyles(moduleExecutionStatus, color);
                }
            }
        }
    }

    private static additionalLogVariant() {
        const htmlBodyEle:HTMLBodyElement = document.querySelector('body'); // getting the body element
        // creating new debug console button
        // creating button
        const debugButtonEle = document.createElement('button');
        debugButtonEle.id = 'ez_debug_button';
        debugButtonEle.setAttribute('type', 'button');
        debugButtonEle.innerHTML = 'Debug Console';
        // creating consoleOutputContainer
        const consoleContainerEle = document.createElement('div');
        consoleContainerEle.id = 'ez_debug_container';
        consoleContainerEle.setAttribute('data-ez-open', 'false');
        // creating console content
        const consoleContentEle = document.createElement('div');
        consoleContentEle.id = 'ez_debug_content';
        htmlBodyEle.append(debugButtonEle);
        htmlBodyEle.append(consoleContainerEle);
        consoleContainerEle.append(consoleContentEle);

        debugButtonEle.addEventListener('click', ()=> {
            if (consoleContainerEle.getAttribute('data-ez-open') === 'false') {
                consoleContainerEle.setAttribute('data-ez-open', 'true');
                Logging.printLogEntrysButtonPress(consoleContentEle);
            }
            else {
                consoleContainerEle.setAttribute('data-ez-open', 'false');
            }
        });
    }

    /** Show the logging by pressing the following keys: Ctrl + Cmd + L */
    private static printLogEntrysButtonPress(contentEle:HTMLDivElement) {
        Logging.buildModulesLog();

        if (document.getElementById('ez_debug_content').innerHTML !== '') document.getElementById('ez_debug_content').innerHTML = '';
        const divEle:HTMLDivElement = document.createElement('div');
        divEle.innerHTML = '*************************************';
        contentEle.append(divEle);

        const divEle2:HTMLDivElement = document.createElement('div');
        divEle2.innerHTML = '*******   eZentrum Modules   ********';
        contentEle.append(divEle2);

        const divEle3:HTMLDivElement = document.createElement('div');
        divEle3.innerHTML = `*******   Version: ${Logging.Modules.VERSION}   ********`;
        contentEle.append(divEle3);

        const divEle4:HTMLDivElement = document.createElement('div');
        divEle4.innerHTML = '*************************************';
        contentEle.append(divEle4);

        const divEleModulesHeader = document.createElement('div');
        divEleModulesHeader.innerHTML = 'Gefundene Module der YAML:';
        contentEle.append(divEleModulesHeader);
        const divEleModules:HTMLDivElement = document.createElement('div');
        divEleModules.innerHTML += Object.keys(Logging.modulesConfiguration);
        contentEle.append(divEleModules);

        const divEleVer2Header:HTMLDivElement = document.createElement('div');
        divEleVer2Header.innerHTML = '******* v2 Modules *******';
        contentEle.append(divEleVer2Header);

        const divEleVer2Modules:HTMLDivElement = document.createElement('div');

        Logging.v2Modals.forEach( ( obj:any ) => {
            divEleVer2Modules.innerHTML += `${obj} `;
        });
        contentEle.append(divEleVer2Modules);

        Logging.MODULES.debugPrint(contentEle);
        const divEle5:HTMLDivElement = document.createElement('div');
        divEle5.innerHTML = "Alle Module wurden in " + Logging.Times.getTimeString(Logging.endTime - Logging.startTime, "s") + " ausgeführt";
        contentEle.append(divEle5);

        Logging.GENERAL_LOG.debugPrint(contentEle);
        Logging.ERROR_LOG.debugPrint(contentEle);
    }


}