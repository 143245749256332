export class Pair<K, V>{

    private key:K;
    private value:V;

    public constructor ( key:K, value:V ){
        this.key = key;
        this.value = value;
    }

    public jsonExport ():string{
        return JSON.stringify( this.key ) + ":"+ JSON.stringify( this.value );
    }

    public getKey ():K {
        return this.key;
    }

    public getValue ():V {
        return this.value;
    }

    public setValue ( v:V ):void {
        this.value = v;
    }

}