import Handlebars from "handlebars";
import { modules } from "../../main";
import { Logging } from "../logging/Logging";


// ****************************************************
// class: View
// A  View has a unique name and a template for processing 
// the rendering with handlebars.
// ****************************************************

export class View {

    private name: string; // Unique name for the view object
    private template: ( data: string ) => any; // handlebar template for rendering the output.

    // ****************************************************
    // Constructor: View
    // Initialize the view with the unique name + template for handlebar rendering.
    // The template for handlebars will be initalized with the parameter "template".
    // Error will be logged, if the template will have errors.
    // ****************************************************
    public constructor( name: string, template: string ) {
        this.name = name;
        try {
            this.template = Handlebars.compile( template );
        } catch (error) {
            throw new Error( "Das folgende Templae hat einen Fehler: " + this.name );
        }
    }

    // ****************************************************
    // Method: process (data:Object, debug: Boolean)
    // Processing the template with the data-object proccessed by handlebar and return the result.
    //
    // Parameter: 
    // data: (Object) Data-Object of type any for processing the template.
    // debug: true|false -> Activate debug mode if true
    //
    // Result: Processed handblebar output.
    // ****************************************************
    public process( data: Object, debug: boolean = false ): string 
    {
        try {
            let result: string = this.template( data as any );
            if ( result != "" ) {
                result = ( debug ? "<!--" + this.name + "-->" : "" ) + result;
            }
            return result;
        } catch (error) {
            Logging.errorMes( "Beim verarbeiten des folgenden Templates ist ein Fehler aufgetretet: " + this.name );
            return null;
        }
    }

    // ******************************
    // Returns the name of the view-object
    // ******************************
    public getName(): string {
        return this.name;
    }

    // ******************************
    // Returns the template of the view-object
    // ******************************
    public getTemplate(): ( data: string ) => any {
        return this.template;
    }
}